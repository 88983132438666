import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Initialiseer Google Analytics
ReactGA.initialize('G-RM4EQBV041');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfTDkwqAAAAACi7pweT3Llb132V6oJeXJNAg3GK"
      scriptProps={{
        async: false, // This is important
        defer: false, // This is important
        appendTo: 'head', // Adds script to head instead of body
      }}
    >
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// Stuur een pageview naar Google Analytics bij het laden van de pagina
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

reportWebVitals();
