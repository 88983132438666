import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, X } from 'lucide-react';

const IntroScreen = ({ onClose, language, currentTheme }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: language === 'nl' ? 'Happy Breather' : 'Happy Breather',
      content: language === 'nl' 
        ? <>Als je dagelijks een ademhalingsoefening doet in deze applicatie, zul je positieve effecten op je gezondheid en welzijn ervaren. Uit <a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC4034215/#abstract2" target="_blank" rel="noopener noreferrer" className="text-pink-300 hover:text-pink-400 underline">wetenschappelijk onderzoek</a> blijkt dat de kans op infecties wordt verlaagd.</>
        : <>If you do a breathing exercise daily in this application, you will experience positive effects on your health and well-being. <a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC4034215/#abstract2" target="_blank" rel="noopener noreferrer" className="text-pink-300 hover:text-pink-400 underline">Scientific research</a> shows that it reduces the risk of infections.</>
    },
    {
      title: language === 'nl' ? 'Rondes' : 'Rounds',
      content: language === 'nl'
        ? <>Kies uit twee soorten ademhalingsoefeningen. De eerste soort heet <i>Rondes</i>. Hier kun je één of meerdere rondes doen van een reeks ademhalingen afgewisseld met het langdurig inhouden van de adem.</>
        : <>Choose from two types of breathing exercises. The first type is called <i>Rounds</i>. Here you can do one or more rounds of a series of breaths alternated with prolonged breath holding.</>
    },
    {
      title: language === 'nl' ? 'Ademhalingen' : 'Breaths',
      content: language === 'nl'
        ? <>De tweede soort ademhalingsoefening heet <i>Ademhalingen</i>. Hier kun je een reeks ademhalingen instellen waarbij je de duur van elke handeling kan instellen.</>
        : <>The second type of breathing exercise is called <i>Breaths</i>. Here you can set a series of breaths where you can set the duration of each action.</>
    },
    {
      title: language === 'nl' ? 'Aan de slag' : 'Get Started',
      content: language === 'nl'
        ? <>Selecteer een favoriet, selecteer <i>Rondes</i> of <i>Ademhalingen</i>, en druk op <i>Start oefening</i> om aan de slag te gaan.</>
        : <>Select a favorite, select <i>Rounds</i> or <i>Breaths</i>, and press <i>Start exercise</i> to get started.</>
    },
    {
      title: language === 'nl' ? 'Veiligheid eerst' : 'Safety first',
      content: language === 'nl'
        ? 'Voor je veiligheid: Doe deze ademhalingsoefeningen niet in of onder water, of tijdens het besturen van een voertuig. Voorkom ongelukken door altijd in een veilige omgeving te oefenen.'
        : 'For your safety: Do not perform these breathing exercises in or under water, or while operating a vehicle. Prevent accidents by always practicing in a safe environment.'
    }
  ];

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      onClose();
    }
  };

  return (
    <div className={`fixed inset-0 bg-gradient-to-br ${currentTheme.from} ${currentTheme.via} ${currentTheme.to} flex items-center justify-center p-4 z-50`}>
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl p-6 sm:p-8 max-w-lg w-full border border-white border-opacity-20"
      >
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-white hover:text-pink-300 transition-colors duration-300"
        >
          <X size={24} />
        </button>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="text-center mb-6"
          >
            <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4 tracking-wide">{slides[currentSlide].title}</h2>
            <div className="h-48 overflow-y-auto px-4 sm:px-6 scrollbar-thin scrollbar-thumb-pink-400 scrollbar-track-transparent">
              <p className="text-lg sm:text-xl text-gray-100 text-justify hyphens-auto leading-relaxed">
                {slides[currentSlide].content}
              </p>
            </div>
          </motion.div>
        </AnimatePresence>
        <div className="flex justify-between items-center mt-4">
          <div className="flex space-x-2">
            {slides.map((_, index) => (
              <motion.div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentSlide ? 'bg-pink-400' : 'bg-gray-400'
                }`}
                initial={false}
                animate={{ scale: index === currentSlide ? 1.2 : 1 }}
                transition={{ duration: 0.3 }}
              />
            ))}
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={nextSlide}
            className="px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-full transition-colors duration-300 flex items-center text-lg font-semibold shadow-lg"
          >
            {currentSlide < slides.length - 1 ? (language === 'nl' ? 'Volgende' : 'Next') : (language === 'nl' ? 'Start' : 'Start')}
            <ChevronRight className="ml-2" size={20} />
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default IntroScreen;
